import {
  Box,
  HStack,
  Stack,
  Container,
  ContainerProps,
} from "@chakra-ui/react";
import { FC, useRef, useState, useEffect } from "react";
import CarouselArrow from "src/atoms/rebrand/Carousel/components/CarouselArrow";
import Card from "../atoms/Card";

const CONTAINER_MAX_WIDTH = 1440;
const DESKTOP_PADDING = 58;

const FeatureCarousel: FC<
  Gatsby.PageBlockFeatureCarouselFragment & ContainerProps
> = ({ items, ...rest }) => {
  const ref = useRef<HTMLDivElement>();

  const [shouldShowArrows, setShouldShowArrows] = useState(false);
  const [prevArrowActive, setPrevArrowActive] = useState(false);
  const [nextArrowActive, setNextArrowActive] = useState(false);

  if (!items) throw Error();

  const checkArrowStatus = () => {
    if (ref.current) {
      if (ref.current.scrollLeft === 0) {
        setPrevArrowActive(false);
      } else {
        setPrevArrowActive(true);
      }

      if (
        Math.abs(ref.current.scrollLeft) ===
        ref.current.scrollWidth - ref.current.clientWidth
      ) {
        setNextArrowActive(false);
      } else {
        setNextArrowActive(true);
      }
    }
  };

  const checkArrows = () => {
    if (ref.current) {
      if (ref.current.scrollWidth - ref.current.clientWidth > 0) {
        setShouldShowArrows(true);
        setNextArrowActive(true);
      } else {
        setShouldShowArrows(false);
        setNextArrowActive(false);
      }
    }
  };

  const sideScroll = (direction: string) => {
    if (ref.current) {
      if (direction === "prev") {
        ref.current.scrollBy({
          left: -392, //Desktop card width + gap (32)
          behavior: "smooth",
        });
      } else {
        ref.current.scrollBy({
          left: 392, //Desktop card width + gap (32)
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    const slider: HTMLDivElement | undefined = ref.current;
    checkArrows();
    window.addEventListener("resize", checkArrows);
    slider?.addEventListener("scroll", checkArrowStatus);
    return () => {
      window.removeEventListener("resize", checkArrows);
      slider?.removeEventListener("scroll", checkArrowStatus);
    };
  }, []);

  //Carousel has a specific design that requires transitioning padding to make layout work with the global container's maxwidth
  const TRANSITION_PADDING = `calc(${DESKTOP_PADDING}px + ((100vw - ${CONTAINER_MAX_WIDTH}px) / 2))`;

  return (
    <Container
      size="full"
      p={{ md: "0" }}
      alignItems="start"
      width="100vw"
      maxWidth="1540px"
      marginRight={{ "2xl": "auto !important" }}
      {...rest}
    >
      <Stack
        ref={ref}
        overflowX="scroll"
        overflowY="hidden"
        whiteSpace="nowrap"
        maxW="100vw"
        w={{ base: "max-content", "2xl" : "full"}}
        mx="auto"
        direction="row"
        paddingX={{
          base: "space-16",
          md: "space-58",
          xl: TRANSITION_PADDING,
          "2xl": 0,
        }}
        spacing={{ base: "space-16", md: "space-32" }}
        paddingTop="space-8" //to give space for item hover
        sx={{
          scrollSnapType: "x mandatory",
          WebkitOverflowScrolling: "touch",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {items?.map((item) => {
          if (!item) return null;

          return (
            <Box
              key={item.rich_description?.text || ""}
              w={{ base: "270px", md: "360px" }}
              flexShrink="0"
              h="inherit"
              sx={{
                scrollSnapAlign: "start",
                scrollSnapStop: "always",
                scrollMargin: {
                  base: "16px",
                  md: "58px",
                  xl: TRANSITION_PADDING,
                  "2xl": 0,
                },
              }}
            >
              <Card item={item} h="full" />
            </Box>
          );
        })}
      </Stack>

      {shouldShowArrows && (
        <HStack
          spacing="space-16"
          mt="space-32"
          w="full"
          justifyContent="center"
          maxW="1434px"
          marginLeft="0"
        >
          <CarouselArrow
            onClick={() => sideScroll("prev")}
            transform={"rotate(-180deg)"}
            disabled={!prevArrowActive}
          />
          <CarouselArrow
            onClick={() => sideScroll("next")}
            disabled={!nextArrowActive}
          />
        </HStack>
      )}
    </Container>
  );
};

export default FeatureCarousel;
