import { Container, VStack } from "@chakra-ui/react";
import { FC } from "react";
import { BlockContainer } from "src/atoms";
import Renderer from "src/lib/renderer";
import FeatureGrid from "./components/FeatureGrid";
import FeatureCarousel from "./components/FeatureCarousel";

import { Element } from "@prismicio/react";
import { heading2, heading3 } from "src/lib/renderer/elements/headings";

const BlockFeatureCarousel: FC<
  Gatsby.PageBlockFeatureCarouselFragment
> = ({ primary, items }) => {
  if (!primary || !items) throw Error();

  const { content } = primary;

  const isGrid = items.length <= 3;

  return (
    <BlockContainer
      paddingX="0"
      sx={{
        "> *": {
          maxWidth: { base: "100vw", md: "100%" },
        },
      }}
      wrapperContainerProps={{
        paddingX: 0,
        overflow: "hidden",
      }}
    >
      <VStack spacing="space-8">
        <Container size="block" paddingY="0">
          <Container variant="heading">
            <VStack
              spacing={{ base: "space-8" }}
              maxW={{ base: "480px", md: "full" }}
              py={{ base: "space-16" }}
              mx="auto"
            >
              <Renderer
                overrides={{
                  [Element.heading2]: (args) =>
                    heading2({
                      ...args,
                      overrideProps: {
                        marginBottom: 0,
                      },
                    }),
                  [Element.heading3]: (args) =>
                    heading3({
                      ...args,
                      overrideProps: {
                        marginBottom: 0,
                        variant: "subtitle",
                      },
                    }),
                }}
                field={content}
              />
            </VStack>
          </Container>
        </Container>

        <FeatureGrid
          items={items}
          primary={primary}
          display={{ base: "none", lg: isGrid ? "flex" : "none" }}
        />
        <FeatureCarousel
          items={items}
          primary={primary}
          display={{ lg: isGrid ? "none" : "flex" }}
        />
      </VStack>
    </BlockContainer>
  );
};

export default BlockFeatureCarousel;
