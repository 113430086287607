import {
  Box,
  SimpleGrid,
  useBreakpointValue,
  HStack,
  BoxProps,
  SimpleGridProps
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { cloneElement, FC, useState } from "react";
import CarouselArrow from "./components/CarouselArrow";
import { motionVariants } from "./utils";

const Carousel: FC<
  BoxProps & {
    items: any[];
    breakPointValue?: { base: number; md: number; lg: number };
    lightColor?: boolean,
    carouselWrapperProps?: SimpleGridProps
  }
> = ({ items, breakPointValue, lightColor, carouselWrapperProps }) => {
  const [[carouselIndex, direction], setCarouselIndex] = useState([0, 0]);

  const carouselBreakpointValue = breakPointValue ?? { base: 1, md: 2, lg: 3 };

  const numberOfItems = useBreakpointValue(carouselBreakpointValue) ?? 0;
  const pagesNumber = Math.ceil(items.length / numberOfItems);
  const shouldShowArrows = Boolean(numberOfItems && pagesNumber > 1);

  return (
    <Box>
      <AnimatePresence custom={direction} exitBeforeEnter initial={false}>
        <motion.div
          custom={direction}
          initial="before"
          animate="in"
          exit="after"
          variants={motionVariants}
          transition={{ ease: "easeOut", duration: 0.2 }}
          key={carouselIndex}
        >
          <SimpleGrid
            templateColumns={{
              base: `repeat(${carouselBreakpointValue.base}, 1fr)`,
              md: `repeat(${carouselBreakpointValue.md}, 1fr)`,
              lg: `repeat(${carouselBreakpointValue.lg}, 1fr)`,
            }}
            spacing="space-32"
            paddingTop="space-64"
            {...carouselWrapperProps}
          >
            {Array(carouselBreakpointValue.lg)
              .fill("")
              .map((_, i) => {
                const item = items[carouselIndex + i];

                return (
                  item &&
                  cloneElement(item, {
                    display: {
                      base:
                        i <= carouselBreakpointValue.base - 1
                          ? "block"
                          : "none",
                      md:
                        i <= carouselBreakpointValue.md - 1 ? "block" : "none",
                      lg:
                        i <= carouselBreakpointValue.lg - 1 ? "block" : "none",
                    },
                  })
                );
              })}
          </SimpleGrid>
        </motion.div>
      </AnimatePresence>

      {shouldShowArrows && (
        <HStack mt="space-40" spacing="space-16" justify="center">
          {
            <CarouselArrow
              aria-label="prev"
              light={lightColor}
              disabled={carouselIndex <= 0}
              onClick={() =>
                setCarouselIndex([
                  Math.max(carouselIndex - numberOfItems, 0),
                  -1,
                ])
              }
              transform="rotate(180deg)"
            />
          }
          {
            <CarouselArrow
              disabled={carouselIndex / numberOfItems >= pagesNumber - 1}
              aria-label="next"
              light={lightColor}
              onClick={() =>
                setCarouselIndex([carouselIndex + numberOfItems, 1])
              }
            />
          }
        </HStack>
      )}
    </Box>
  );
};

export default Carousel;
