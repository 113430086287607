import {
  Box,
  Stack,
  Flex,
  VStack,
  Text,
  HStack,
  chakra,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Element } from "@prismicio/react";
import { FC, Fragment } from "react";
import Renderer from "src/lib/renderer";
import { heading2, heading3 } from "src/lib/renderer/elements/headings";
import { BlockContainer, IconBox } from "src/atoms";
import { PrismicLink } from "src/atoms";
import { SvgOrImg } from "src/components/SvgOrImg";

const BlockSecurity: FC<Gatsby.PageBlockSecurityFragment> = ({
  primary,
  items,
}) => {
  if (!primary) throw Error();

  const { content, buttons } = primary;
  const size = useBreakpointValue({ base: 16, md: 24 }) || 16;

  return (
    <BlockContainer>
      <Stack
        direction={{ base: "column", md: "row" }}
        borderRadius="lg"
        bg="greyLight-3"
        w="full"
        p={{ base: "space-24", md: "space-80" }}
        spacing={{ base: "space-16", md: "space-80" }}
        align="center"
      >
        <VStack
          textAlign={{ base: "center", md: "left" }}
          spacing={{ base: "space-16", md: "space-24" }}
          align={{ base: "center", md: "flex-start" }}
        >
          <Renderer
            overrides={{
              [Element.heading2]: (args) =>
                heading2({
                  ...args,
                  overrideProps: {
                    variant: "heading-1",
                    marginBottom: 0,
                  },
                }),
              [Element.heading3]: (args) =>
                heading3({
                  ...args,
                  overrideProps: {
                    variant: "heading-4",
                    marginBottom: 0,
                  },
                }),
            }}
            field={content}
          />
          <Box
            display={{ base: "none", md: "block" }}
            sx={{
              p: {
                _last: {
                  marginBottom: 0,
                },
              },
              ".chakra-button": {
                w: { base: "full", md: "auto" },
              },
            }}
          >
            <Renderer field={buttons} />
          </Box>
        </VStack>
        <VStack w="full" maxW={{ md: "526px" }}>
          {items?.map((item) => {
            if (!item) return null;

            const isLink = Boolean(item?.link?.url);

            return (
              <HStack
                bg="white"
                key={item.label}
                p="space-8"
                borderRadius="lg"
                w="full"
                spacing="space-8"
                position="relative"
                sx={{
                  ":hover": {
                    ".element-cta-icon": {
                      transform: (theme) => `translateX(${theme.space[1]})`,
                    },
                  },
                }}
              >
                <IconBox
                  w={{ base: "40px", md: "56px" }}
                  h={{ base: "40px", md: "56px" }}
                  bg="greyLight-4"
                  borderRadius={{ base: "12px", md: "lg" }}
                  justifyContent="center"
                  alignItems="center"
                >
                  {item?.icon?.document?.__typename === "PrismicElementImage"
                    ? <SvgOrImg
                        additionalBoxProps={{
                          maxW: { base: "16px", md: "24px"},
                          maxH: { base: "16px", md: "24px"}
                        }}
                        imageFile={item?.icon?.document?.data?.image_file}
                        isBox={true}
                        size={size}
                      />
                    : null
                  }
                </IconBox>
                <Flex w="full" alignItems="center">
                  <Text as="div" textAlign="left" pr="space-8" lineHeight="short">
                    {item.label}
                  </Text>
                  {isLink ? (
                    <Fragment>
                      <chakra.span
                        transition="transform .2s ease-out"
                        marginLeft="auto"
                        w="30px"
                        className="element-cta-icon"
                      >
                        →
                      </chakra.span>
                      <Box
                        as={PrismicLink}
                        link={item.link!}
                        position="absolute"
                        w="full"
                        h="full"
                        top="0"
                        left="0"
                      />
                    </Fragment>
                  ) : null}
                </Flex>
              </HStack>
            );
          })}
        </VStack>
        <Box
          display={{ md: "none" }}
          w="full"
          sx={{
            p: {
              _last: {
                marginBottom: 0,
              },
            },
            ".chakra-button": {
              w: { base: "full", md: "auto" },
            },
          }}
        >
          <Renderer field={buttons} />
        </Box>
      </Stack>
    </BlockContainer>
  );
};

export default BlockSecurity;
