import { VStack, Box, ButtonProps, LinkProps } from "@chakra-ui/react";
import { FC, isValidElement } from "react";
import { BlockContainer, IconBox } from "src/atoms";

import { heading2 } from "src/lib/renderer/elements/headings";
import { Element } from "@prismicio/react";
import { hyperlink } from "src/lib/renderer/elements/hyperlink";
import Renderer from "src/lib/renderer";
import { SvgOrImg } from "src/components/SvgOrImg";

import { getBackgroundColor } from "./utils";

const BlockCenter: FC<Gatsby.PageBlockCenterFragment> = ({ primary }) => {
  if (!primary) throw Error();

  const { background_color, content, icon } = primary;

  const isLightGreyVariant =
    background_color === "light grey" || background_color === "white";

  return (
    <BlockContainer>
      <VStack
        bg={getBackgroundColor(background_color!)}
        borderRadius="lg"
        padding={{ base: "space-24", md: "space-80" }}
        spacing="space-24"
        w="full"
        sx={{
          "> *, a": {
            color: isLightGreyVariant ? "text-primary" : "greyLight-5",
            maxWidth: "800px",
          },
          h2: {
            color: isLightGreyVariant ? "text-primary" : "greyLight-5",
            marginBottom: 0,
          },
          "a.btn-primary": {
            color: isLightGreyVariant ? "greyLight-5" : "text-primary",
          },
        }}
      >
        <IconBox bg={background_color === "white" ? "grey-0" : "white"}>
          <SvgOrImg
            imageFile={icon?.document?.data?.image_file}
            isBox={true}
            size={24}
          />
        </IconBox>
        <Renderer
          overrides={{
            [Element.heading2]: (args) =>
              heading2({
                ...args,
                overrideProps: {
                  variant: "heading-3",
                },
              }),
            [Element.hyperlink]: (args) => {
              let overrideProps: ButtonProps & LinkProps = {
                marginTop: { base: 0, md: "space-8" },
              };

              const isPrimaryButton = args?.children.some(
                (child) =>
                  isValidElement(child) &&
                  child.props.className === "button-primary",
              );

              if (isPrimaryButton) {
                overrideProps = {
                  ...overrideProps,
                  variant: !isLightGreyVariant ? "lightGrey" : "primary",
                };
              }

              return hyperlink({
                ...args,
                overrideProps: { ...overrideProps },
              });
            },
          }}
          field={content}
        />
      </VStack>
    </BlockContainer>
  );
};

export default BlockCenter;
