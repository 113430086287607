import { Box, Divider, Flex, Heading } from "@chakra-ui/react";
import { FC } from "react";
import { BlockContainer, Card } from "src/atoms";
import Renderer from "src/lib/renderer";

const BlockContactCard: FC<Gatsby.BlockContactCardFragment> = ({ primary }) => {
  if (!primary) throw Error();

  const { content } = primary;

  return (
    <BlockContainer withBackground>
      <Box width="100%">
        <Card
          padding="space-48"
          maxWidth="480px"
          marginX="auto"
          textAlign="left"
        >
          <Heading as="h3" variant="subtitle-medium" textAlign="center">
            CONTACT
          </Heading>

          <Divider marginY="space-24" />

          <Renderer field={content} />
        </Card>
      </Box>
    </BlockContainer>
  );
};

export default BlockContactCard;
