import ElementAnimation from "src/components/elements/ElementAnimation";
import Renderer from "src/lib/renderer";
import {
  Box,
  Flex,
  Grid,
  VStack,
  HStack,
  Stack,
  Text,
  Wrap,
} from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";
import { BlockContainer, IconBox, ShiIcon } from "src/atoms";

import { Element } from "@prismicio/react";
import { heading2, heading3 } from "src/lib/renderer/elements/headings";
import { hyperlink } from "src/lib/renderer/elements/hyperlink";
import { isValidElement } from "react";
import { getContentAlignment } from "./utils";

const BlockColumns: FC<Gatsby.PageBlockColumnsFragment> = ({
  primary,
  items,
}) => {
  if (!primary) throw Error();

  const {
    content,
    buttons,
    media_position,
    media,
    hide_media_on_mobile,
    sticker,
    is_large: isLarge,
    is_quote,
    alignment,
  } = primary;

  const isMediaLeft = media_position === "left";
  const gridChildMarginLeft = isLarge ?
    { md: isMediaLeft ? "space-40" : "0", xl: isMediaLeft ? "space-80" : "0" } :
    { md: isMediaLeft ? "space-80" : "0" };

  const gridChildMarginRight = isLarge ?
    { md: !isMediaLeft ? "space-40" : "0", xl: !isMediaLeft ? "space-80" : "0" } :
    { md: !isMediaLeft ? "space-80" : "0" };

  const gridChildPaddingLeft = isLarge ?
    { base: "0", md: !isMediaLeft ? "space-58" : "0", xl: "0" } :
    { md: !isMediaLeft ? "space-58" : "0" };

  const gridChildPaddingRight = isLarge ?
    { base: "0", md: isMediaLeft ? "space-58" : "0", xl: "0" } :
    { md: isMediaLeft ? "space-58" : "0" };

  const vStackAdditionalProps = isLarge ?
    {
      marginLeft: { md: "0" },
      marginRight: { md: "0" },
    } :
    {
      marginLeft: { md: isMediaLeft ? "0" : "auto" },
      marginRight: { md: !isMediaLeft ? "0" : "auto" },
      spacing: { base: "space-16", md: "space-24" },
      maxWidth: { md: "408px" }
    };

  const hStackAdditionalProps = isLarge ?
    {
      borderRadius: "10px",
      paddingX: "space-16",
      paddingY: "space-8",
      backgroundColor: "white"
    } : {};

  const heading2Props = isLarge ? {
    marginBottom: "space-8"
  } : {
    variant: 'heading-3',
    marginBottom: "space-16"
  };

  const heading3Props = isLarge ? {
    marginBottom: "space-8"
  } : {
    variant: 'subtitle',
    marginBottom: "space-16"
  };

  const boxSxAdditionalProps = isLarge ? {
    "p:last-of-type": {
      marginBottom: "space-16",
    },
    p: {
      fontFamily: "heading",
      fontSize: "font-20",
    }
  } : {
    p: {
      marginBottom: "space-16",
      _last: {
        marginBottom: 0,
      },

      strong: {
        fontWeight: "normal"
      }
    },
  };

  return (
    <BlockContainer
      paddingX={{ base: "space-16", md: 0 }}
      maxWidth={{ xl: "1600px", "2xl": "1434px" }}
    >
      <Grid
        className="bodyblock-grid"
        gridGap={{ base: "space-16", md: "0" }}
        gridTemplateColumns={{
          base: "auto",
          md: " 1fr 1fr",
        }}
        gridTemplateAreas={{
          base: hide_media_on_mobile ? '"content"' : '"media" "content"',
          md: isMediaLeft ? '"media content"' : '"content media"',
        }}
      >
        <Box
          alignSelf={getContentAlignment(alignment)}
          gridArea="content"
          marginLeft={gridChildMarginLeft}
          marginRight={gridChildMarginRight}
          paddingLeft={gridChildPaddingLeft}
          paddingRight={gridChildPaddingRight}
        >
          <VStack
            alignItems={{ base: "center", md: "flex-start" }}
            textAlign={{ base: is_quote ? "center" : "left", md: "left" }}
            {...vStackAdditionalProps}
          >
            {is_quote && (
              <ShiIcon
                name="quotationmark"
                boxSize={{ base: "52px", md: "104px" }}
                transform="rotate(-180deg)"
                alignSelf={{ base: "center", md: "flex-start" }}
              />
            )}
            <Box
              sx={{
                ...boxSxAdditionalProps,
                ".chakra-badge": {
                  marginBottom: { base: "space-8", md: "space-16" },
                },
              }}
            >
              <Renderer
                overrides={{
                  [Element.heading2]: (args) =>
                    heading2({
                      ...args,
                      overrideProps: {
                        ...heading2Props
                      },
                    }),
                  [Element.heading3]: (args) =>
                    heading3({
                      ...args,
                      overrideProps: {
                        ...heading3Props,
                      },
                    }),
                }}
                field={content}
              />
            </Box>

            {!isLarge ? <Stack
              direction={{ base: "column", md: "row" }}
              w={{ base: "full", md: "auto" }}
              spacing={{ base: "space-24", md: "space-40" }}
              align="center"
              pt={{ base: "space-16", md: "space-8" }}
              sx={{
                ".btn-primary": {
                  width: "100%",
                },
                ".btn-secondary": {
                  width: "auto",
                },
                p: {
                  marginBottom: 0,
                  w: "full",
                },
              }}
              textAlign="center"
            >
              <Renderer
                overrides={{
                  [Element.hyperlink]: (args) => {
                    const isSecondaryButton = args?.children.some(
                      (child) =>
                        isValidElement(child) &&
                        child.props.className === "button-secondary",
                    );

                    return hyperlink({
                      ...args,
                      hasArrow:
                        isSecondaryButton && !is_quote ? false : undefined,
                      overrideProps: {},
                    });
                  },
                }}
                field={buttons}
              />
            </Stack> : null}
            {items?.length > 0 && (
              <Wrap
                spacing="space-16"
                justify={{ base: isLarge ? "left" : "center", md: "start" }}
                pt={{ base: "space-16", md: 0 }}
              >
                {items?.map((item) => {
                  if (!item) return null;

                  const icon = item.icon?.document;

                  return (
                    <HStack
                      key={item.label}
                      spacing="space-8"
                      align="start"
                      {...hStackAdditionalProps}
                    >
                      {icon?.__typename === "PrismicElementImage" && (
                        <IconBox bg="white" w="28px" h="28px" borderRadius="md">
                          <Box
                            as={GatsbyImage}
                            image={icon.data?.image_file?.gatsbyImageData}
                            alt={icon.data?.image_file?.alt ?? ""}
                          />
                        </IconBox>
                      )}
                      {isLarge && icon?.__typename !== "PrismicElementImage" ? <IconBox bg="white" w="28px" h="28px" borderRadius="md">
                        <ShiIcon
                          name="check"
                          boxSize="space-16"
                        />
                      </IconBox> : null}
                      <Box>
                        <Text
                          as="div"
                          variant="body-small"
                          mt={{ base: "3px", md: "5px" }}
                        >
                          {item.label}
                        </Text>
                      </Box>
                    </HStack>
                  );
                })}
              </Wrap>
            )}
          </VStack>
        </Box>

        <Box
          gridArea="media"
          display={{
            base: hide_media_on_mobile ? "none" : "block",
            md: "block",
          }}
          position="relative"
        >
          {sticker?.document?.__typename === "PrismicElementImage" && (
            <Flex
              justifyContent="center"
              alignItems="center"
              position="absolute"
              top={{ base: "-59px", md: "-90px" }}
              left={{
                base: isMediaLeft ? "0" : "unset",
                md: isMediaLeft ? "134px" : "unset",
              }}
              right={{
                base: !isMediaLeft ? "0" : "unset",
                md: !isMediaLeft ? "134px" : "unset",
              }}
              w={{ base: "118px", md: "180px" }}
              h={{ base: "118px", md: "180px" }}
              zIndex="1"
            >
              <Box
                as={GatsbyImage}
                image={sticker.document.data?.image_file?.gatsbyImageData}
                alt={sticker.document.data?.image_file?.alt ?? ""}
              />
            </Flex>
          )}

          <Box
            borderRadius={{ base: "lg", md: "unset", "2xl": isLarge ? "unset" : "lg" }}
            overflow="hidden"
            sx={{
              img: {
                borderRadius: { base: "lg", md: "unset", "2xl": isLarge ? "unset" : "lg" }, //required to override gatsby image default css
              },
            }}
          >
            {media?.document?.__typename === "PrismicElementImage" && (
              <Box
                as={GatsbyImage}
                image={media.document.data?.image_file?.gatsbyImageData}
                alt={media.document.data?.image_file?.alt ?? ""}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            )}

            {media?.document?.__typename === "PrismicElementAnimation" && (
              <ElementAnimation data={media.document.data} />
            )}
          </Box>
        </Box>
      </Grid>
    </BlockContainer>
  );
};

export default BlockColumns;
