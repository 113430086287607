import {
  Center,
  Heading,
  SimpleGrid,
  Text,
  VStack,
  Stack,
  Box,
  Container,
} from "@chakra-ui/react";

import { FC } from "react";
import { BlockContainer } from "src/atoms";
import Renderer from "src/lib/renderer";
import { Element } from "@prismicio/react";
import { hyperlink } from "src/lib/renderer/elements/hyperlink";
import { isValidElement } from "react";
import { heading2 } from "src/lib/renderer/elements/headings";
import paragraph from "src/lib/renderer/elements/paragraph";

const BlockSteps: FC<Gatsby.PageBlockStepsFragment> = ({ primary, items }) => {
  if (!primary || !items) throw Error();

  const { buttons, content } = primary;

  return (
    <BlockContainer>
      <VStack spacing={{ base: "space-40", md: "space-80" }}>
        <Container variant="heading">
          <VStack direction="column" align="center" spacing="space-16">
            <Renderer
              field={content}
              overrides={{
                [Element.heading2]: (args) =>
                  heading2({
                    ...args,
                    overrideProps: {
                      marginBottom: { base: 0, md: 0 },
                    },
                  }),
                [Element.paragraph]: (args) =>
                  paragraph({
                    ...args,
                    overrideProps: {
                      variant: "subtitle",
                      _last: {
                        marginBottom: 0,
                      },
                    },
                  }),
              }}
            />
          </VStack>
        </Container>
        <SimpleGrid
          columns={{ base: 1, md: items?.length > 4 ? 4 : items.length }}
          spacing={{ base: "space-32", md: "space-48" }}
          w={{ base: "full", md: "auto" }}
        >
          {items.map((step, index) => {
            if (!step) return null;

            return (
              <VStack
                key={step.label}
                align="flex-start"
                textAlign={{ base: "left", md: "center" }}
                maxW={{ base: "unset", md: "322px" }}
              >
                <Stack
                  direction={{ base: "row", md: "column" }}
                  spacing={{ base: "space-16" }}
                  align={{ base: "flex-start" }}
                >
                  <Center
                    bg="yellow-0"
                    borderRadius="full"
                    color="text-primary"
                    fontSize="font-15"
                    justify="center"
                    lineHeight="short"
                    w={{ base: "40px", md: "56px" }}
                    h={{ base: "40px", md: "56px" }}
                    margin={{ md: "auto" }}
                  >
                    {`0${index + 1}`}
                  </Center>
                  <Box flex="1">
                    <Heading
                      as="h3"
                      variant="subtitle-large"
                      mb={{ base: "space-8", md: "space-16" }}
                    >
                      {step.label}
                    </Heading>
                    <Text as="div">{step.description}</Text>
                  </Box>
                </Stack>
              </VStack>
            );
          })}
        </SimpleGrid>
        <Stack
          w="full"
          direction={{ base: "column", md: "row" }}
          spacing={{ base: "space-24", md: "space-40" }}
          justify="center"
          align="center"
          sx={{
            ".chakra-button": {
              width: { base: "100%", md: "auto" },
              marginBottom: "18px",
            },
            ".btn-secondary": {
              width: "auto",
            },
            ".chakra-button:last-of-type": {
              marginBottom: 0,
            },
            p: {
              marginBottom: 0,
              w: { base: "full", md: "auto" },
            },
          }}
        >
          <Renderer
            overrides={{
              [Element.hyperlink]: (args) => {
                const isSecondaryButton = args?.children.some(
                  (child) =>
                    isValidElement(child) &&
                    child.props.className === "button-secondary",
                );

                return hyperlink({
                  ...args,
                  hasArrow: isSecondaryButton ? false : undefined,
                  overrideProps: {},
                });
              },
            }}
            field={buttons}
          />
        </Stack>
      </VStack>
    </BlockContainer>
  );
};

export default BlockSteps;
