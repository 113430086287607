import {
  VStack,
  Box,
  Heading,
  Container,
  Center,
  Divider,
  List,
  Grid,
  Icon,
  Circle,
  Flex,
  Text,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { FC, Fragment } from "react";
import { Card, ShiIcon } from "src/atoms";
import Renderer from "src/lib/renderer";
import { ReactComponent as RocketIcon } from "src/assets/icons/rocket.svg";
import { ReactComponent as CardIcon } from "src/assets/icons/card.svg";
import { PriceUnderline } from "src/templates/Page/slices/PricingPlans/components/PlanCard/styles";
import { GatsbyImage } from "gatsby-plugin-image";

const BOTTOM_OVERFLOW = "300px";

type PricingPreset = "cap dep + compte pro" | "micro + compte pro";

const BlockPricingDisplay: FC<
  Gatsby.PageBlockPricingDisplayFragment & {
    primary: {
      preset: PricingPreset | null;
    };
  }
> = ({ primary, items }) => {
  if (!primary || !items) throw Error();

  const {
    content,
    title,
    price,
    crossed_out_price,
    price_description,
    recurrence,
    tax,
    buttons,
    background_image,
    preset,
  } = primary;

  return (
    <Box paddingBottom={BOTTOM_OVERFLOW} width="100%">
      <Container
        as={Center}
        textAlign="center"
        flexDirection="column"
        paddingTop="space-48"
        backgroundColor="ctaBlue-6"
        position="relative"
        backgroundRepeat="no-repeat"
        backgroundPosition="bottom center"
      >
        {background_image?.document?.__typename === "PrismicElementImage" && (
          <Box position="absolute" bottom={0}>
            <GatsbyImage
              image={
                background_image.document.data?.image_file?.gatsbyImageData
              }
              alt={background_image.document.data?.image_file?.alt ?? ""}
            />
          </Box>
        )}

        <Flex
          direction="column"
          align="center"
          maxW="730px"
          paddingY="space-48"
          sx={{
            h2: {
              fontSize: "font-40",
            },
          }}
        >
          <Renderer field={content} />
        </Flex>

        <Card
          as={VStack}
          marginBottom={`-${BOTTOM_OVERFLOW}`}
          maxWidth="440px"
          spacing="space-32"
          paddingY="space-40"
          paddingX="space-32"
          zIndex="docked"
        >
          <Heading as="h3" variant="subtitle-large">
            {title}
          </Heading>

          {preset && (
            <Fragment>
              <Grid
                templateColumns="1fr auto 1fr"
                width="100%"
                alignItems="end"
              >
                <VStack spacing="space-8">
                  <Icon as={RocketIcon} fontSize="72px" />
                  <Heading as="h3" variant="subtitle-small" fontSize="font-14">
                    {preset === "cap dep + compte pro" && "Dépôt de capital"}
                    {preset === "micro + compte pro" && "Création de micro"}
                  </Heading>
                </VStack>

                <Circle
                  fontSize="font-30"
                  boxSize="50px"
                  bg="lightBlue-5"
                  fontWeight="medium"
                  color="lightBlue-2"
                >
                  +
                </Circle>

                <VStack spacing="space-8">
                  <Icon as={CardIcon} fontSize="52px" />
                  <Heading as="h3" variant="subtitle-small" fontSize="font-14">
                    Compte pro
                  </Heading>
                </VStack>
              </Grid>

              <Divider maxWidth="75%" />
            </Fragment>
          )}

          <Box>
            <Flex width="100%" justify="center" alignItems="flex-start">
              <Text as="span" color="text-secondary">
                {tax}
              </Text>

              <Box width="fit-content" fontSize="font-52">
                <Text data-id="price" fontWeight="medium">
                  {price}
                </Text>
                <PriceUnderline color="yellow-3" />
              </Box>

              {recurrence && (
                <Text
                  as="span"
                  display="inline-block"
                  marginLeft="space-8"
                  alignSelf="flex-end"
                  color="text-secondary"
                >
                  {recurrence}
                </Text>
              )}
            </Flex>

            {crossed_out_price && (
              <Text
                data-id="crossed_out_price"
                color="greyLight-3"
                textDecoration="line-through"
              >
                {crossed_out_price}
              </Text>
            )}

            {price_description && (
              <Box
                marginTop="space-16"
                sx={{
                  ".chakra-text": {
                    color: "text-secondary",
                    fontSize: "font-16",
                  },
                }}
              >
                <Renderer field={price_description} />
              </Box>
            )}
          </Box>

          <Divider maxWidth="75%" />

          {items.length > 0 && (
            <List width="100%" textAlign="left" spacing="space-8">
              {items.map((item, i) => {
                if (!item) return null;

                const { feature } = item;

                return (
                  <ListItem display="flex" key={i}>
                    <ListIcon
                      as={() => (
                        <ShiIcon
                          m="space-4"
                          name="check"
                          color="turquoise-2"
                          fontSize="20px"
                        />
                      )}
                    />

                    <Box
                      marginLeft="space-12"
                      sx={{
                        ".chakra-text": {
                          color: "text-primary",
                          fontSize: "font-16",
                        },
                      }}
                    >
                      <Renderer field={feature} />
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          )}

          <Box
            paddingTop="space-24"
            sx={{
              ".chakra-text": {
                fontSize: "font-14",
              },
            }}
          >
            <Renderer field={buttons} />
          </Box>
        </Card>
      </Container>
    </Box>
  );
};

export default BlockPricingDisplay;
